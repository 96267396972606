// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {useEffect, useMemo, useRef, useState} from 'react'
import type { AxiosError, AxiosResponse } from 'axios'
import BaseService from '../../global/BaseService'
import {Skeleton} from "primereact/skeleton";
import {Image} from "primereact/image";
import {getPreviewBlobNameV2, getThumbBlobName} from "../../global/utils";
import ImageView from "../../components/ui/ImageView";
import {Button} from "../../components/ui/Button";
import {HiOutlineThumbDown, HiOutlineThumbUp} from "react-icons/hi";
import {IoClose} from "react-icons/io5";

type InspectionsProps = {
    asset: Asset,
    inspection: InspectionDto,
    activeImageIndex: number,
    setActiveImageIndex: (val: number) => void,
}

const Images = ({ inspection, activeImageIndex, setActiveImageIndex }: InspectionsProps) => {
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [previewImage, setPreviewImage] = useState([])
    const [selectedImage, setSelectedImage] = useState(null)
    const [canvasImage, setCanvasImage] = useState(null)
    const [showPopup, setShowPopup] = useState(false)
    const [thumbsStatus, setThumbsStatus] = useState([])
    const [imageReset, setImageReset] = useState(false);
    const canvasRef = useRef(null)
    const imageRef = useRef(null)
    const controllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        if (images.length === 0) {
            handlePreview(inspection?.images[0].blobName, 0)
            getImages();
            getThumbsDownReasons();
        }
    }, [inspection]);

    useEffect(() => {
        if (activeImageIndex) {
            setImageReset(true);
        }
    }, [activeImageIndex]);

    useEffect(() => {
        if (imageReset) {
            setImageReset(false)
        }
    }, [imageReset]);

    /**
     * Retrieves the list of thumbs-down feedback reasons for the current asset.
     * Updates the state with the retrieved feedback data.
     */
    async function getThumbsDownReasons() {
        const param = {
            url: `/ml-model/find-feedbacks?assetId=${inspection?.images[0].assetId}`,
            method: 'get',
            params: {},
        }
        BaseService(param).then((response: AxiosResponse<Response>) => {
            setThumbsStatus(response.data)
        })
    }

    async function getImages() {
        setImages([])
        if (inspection.images.length) {
            setLoading(true)
            setActiveImageIndex(0)
            const param = {
                url: '/storage/blob-names',
                method: 'get',
                params: {
                    blobNames: inspection.images.map(image => getThumbBlobName(image.blobName))
                }
            };
            BaseService(param)
                .then((response: AxiosResponse<Response>) => {
                    setLoading(false)
                    setImages(response.data)
                })
                .catch((errors: AxiosError) => {
                    setLoading(false)
                    console.log('errors', errors);
                })
        }
    }

    const handlePreview = (blobName, activeIndex) => {

        // If there's an existing controller, abort it and clear the canvas
        if (controllerRef.current) {
            controllerRef.current.abort();
            setCanvasImage(null); // Clear canvas before new request
        }

        // Create a new AbortController for the current request
        const controller = new AbortController();
        controllerRef.current = controller;

        const param = {
            url: '/storage/blob-names',
            method: 'get',
            params: {
                blobNames: [getPreviewBlobNameV2(blobName)],
            },
            signal: controller?.signal,
        }
        BaseService(param)
            .then((response: AxiosResponse<Response>) => {
                setPreviewImage(response.data[0].str)
                setTimeout(() => {
                    handleDraw(true, activeIndex)
                }, 200)
            }).catch((error) => {
            // Handle the cancellation error gracefully
            if (error.name === 'CanceledError') {
                return; // Do nothing if the request was canceled
            }
        });

    }

    /**
     * Draws bounding boxes and confidence scores on the canvas based on the image predictions.
     * Scales and adjusts the drawing based on whether the image is a preview or full-resolution.
     *
     * @param {boolean} preview - Whether the image being drawn is a preview.
     * @param activeImageIndex
     */
    function handleDraw(preview: boolean, activeImageIndex: number) {
        if (!imageRef.current?.getImage().width){
            handleDraw(preview, activeImageIndex)
        }
        const inspectionImage = inspection.images.find(
            (image) => image.id === inspection.images[activeImageIndex].id,
        )
        if (preview && !inspectionImage.width) return // Skip drawing if preview is true and width is not available

        let imgPrediction = null
        const imgPredictionList = inspectionImage.label?.img_prediction_list
        if (imgPredictionList?.length) imgPrediction = imgPredictionList[0] // Get the first prediction if available
        if (imgPrediction && imgPrediction.b_box.length) {
            // Proceed if there are bounding boxes
            const ctx = canvasRef.current.getContext('2d')

            const width = imageRef.current.getImage().width
            const height = imageRef.current.getImage().height
            const naturalWidth = preview
                ? inspectionImage.width
                : imageRef.current.getImage().naturalWidth
            const multiplier = width / naturalWidth // Calculate the scaling multiplier

            canvasRef.current.width = width // Set canvas dimensions to match the image
            canvasRef.current.height = height

            ctx.drawImage(
                imageRef.current.getImage(),
                0,
                0,
                canvasRef.current.width,
                canvasRef.current.height,
            )

            // Set the font size and line width based on the preview flag
            const fontSize = preview ? 15 : 70
            ctx.font = `${fontSize}px roboto`
            const lineWidthStroke = preview ? 5 : 20
            ctx.lineWidth = lineWidthStroke

            let i = 0
            for (const bbox of imgPrediction.b_box) {
                const x1 = bbox[0][0] * multiplier
                const y1 = bbox[0][1] * multiplier
                const x2 = bbox[1][0] * multiplier
                const y2 = bbox[1][1] * multiplier
                ctx.beginPath()
                ctx.rect(x1, y1, x2 - x1, y2 - y1)
                ctx.strokeStyle = 'red'
                ctx.stroke()

                const conf = (imgPrediction.conf_list[i] * 100).toFixed(0)
                const confText = `Insulator ${conf}%`
                ctx.fillStyle = 'red'
                const fillWidth = ctx.measureText(confText).width
                // Adjust the text box position and size to center the text
                const fillHeight = preview ? 15 : 70
                ctx.fillRect(
                    x1 - (preview ? 2 : 10),
                    y1 - fillHeight,
                    fillWidth,
                    fillHeight,
                )
                ctx.fillStyle = 'white'
                ctx.fillText(confText, x1 - 2, y1 - 3)
                i++
            }

            const dataURL = canvasRef.current.toDataURL('image/png') // Convert the canvas content to a data URL
            setCanvasImage(dataURL) // Convert the canvas content to a data URL
        } else {
            setCanvasImage(null) // Clear the canvas image if no predictions are available
        }
    }

    // Memoized feedback status of the current image
    const currentImageHasFeedback = useMemo(() => {
        if (!thumbsStatus) return null
        return (
            thumbsStatus?.filter(
                (item) =>
                    item?.imageId ===
                    inspection?.images?.[activeImageIndex]?.id,
            ) || null
        )
    }, [thumbsStatus, inspection, activeImageIndex])

    /**
     * Creates the feedback data object with details about the image and the feedback provided.
     * Optionally includes a reason for negative feedback.
     *
     * @param {boolean} feedback - The feedback value (true for positive, false for negative)
     * @param {string} [reason=''] - The reason for negative feedback (optional)
     * @returns {object} The feedback data object
     */
    const createFeedbackData = (feedback, reason = '') => ({
        assetId: inspection?.images?.[activeImageIndex]?.assetId,
        imageId: inspection?.images?.[activeImageIndex]?.id,
        contentType: inspection?.images?.[activeImageIndex]?.contentType,
        blobName: inspection?.images?.[activeImageIndex]?.blobName,
        feedback,
        reason,
    })

    /**
     * Sends the feedback data to the server.
     * Sets the loading state during the request and marks all images as reviewed after submission.
     *
     * @param {object} data - The feedback data object to be sent
     */
    const sendFeedback = async (data) => {
        setLoading(true)
        try {
            await BaseService({
                url: '/ml-model/create-feedback/',
                method: 'post',
                data,
            })
        } finally {
            setLoading(false)
            getThumbsDownReasons()
        }
    }

    /**
     * Deletes feedback with the specified ID and refreshes the list of thumbs-down reasons.
     *
     * @param {number|string} id - The ID of the feedback to delete.
     */
    const deleteFeedback = async (id) => {
        try {
            await BaseService({
                url: `/ml-model/delete-feedback/${id}`,
                method: 'delete',
            })
        } catch (error) {
            console.error(error)
        } finally {
            getThumbsDownReasons()
        }
    }

    /**
     * Handles the thumb action by sending or deleting feedback based on the current state.
     * Specifically, this function handles positive feedback and toggles a popup for negative feedback.
     *
     * @param {boolean} thumbFeedback - The feedback value:
     *   - `true` for positive feedback (thumbs up)
     *   - `false` for negative feedback (thumbs down)
     */
    const handleThumbAction = (thumbFeedback) => {
        // Only handle positive feedback actions
        if (thumbFeedback) {
            // Create feedback data for positive feedback
            const feedbackData = createFeedbackData(thumbFeedback)

            // Check if there's existing positive feedback
            const existingFeedback = currentImageHasFeedback?.find(
                (feed) => feed.feedback === true,
            )

            if (existingFeedback) {
                // If positive feedback already exists, delete it
                deleteFeedback(existingFeedback.id)
            } else {
                // Otherwise, send new positive feedback
                sendFeedback(feedbackData)

                const existingFeedbacks = currentImageHasFeedback?.filter(
                    (feed) => feed.feedback === false,
                )
                existingFeedbacks?.forEach((feedback) => {
                    deleteFeedback(feedback.id)
                })
            }
        } else {
            setShowPopup((prevState) => !prevState)
        }
    }

    /**
     * Handles the selection of a reason for negative feedback.
     * If there is existing positive feedback for the same reason, it is deleted.
     * It then handles the negative feedback by either deleting existing negative feedback
     * or sending new negative feedback based on the current state.
     * Finally, it closes the feedback popup.
     *
     * @param {string} reason - The reason for the negative feedback.
     */
    const handleReasonSelect = (reason) => {
        const data = createFeedbackData(false, reason)

        // Check for positive feedback for the same reason
        const positiveFeedbackExists = currentImageHasFeedback?.find(
            (feed) => feed.feedback === true,
        )
        if (positiveFeedbackExists) {
            deleteFeedback(positiveFeedbackExists.id)
        }

        const existingFeedback = currentImageHasFeedback?.find(
            (feed) => feed.reason === reason,
        )
        if (existingFeedback) {
            deleteFeedback(existingFeedback?.id)
        } else {
            sendFeedback(data)
        }
    }

    /**
     * Closes the reason selection popup.
     */
    const handlePopupClose = () => {
        setShowPopup(false)
    }

    const isPositiveFeedback = currentImageHasFeedback?.some(
        (feed) => feed?.feedback === true,
    )
    const isNegativeFeedback = currentImageHasFeedback?.some(
        (feed) => feed?.feedback === false,
    )

    return (
        <div className="flex flex-col flex-grow overflow-hidden">
            <canvas
                ref={canvasRef}
                id="myCanvas"
                width={0}
                height={0}
                style={{display: 'none'}}
            ></canvas>
            <div className="flex-[3] flex items-center justify-center h-0 overflow-hidden px-10 relative">
                {loading && (
                    <Skeleton
                        width="80%"
                        height="80%"
                        borderRadius="16px"
                    ></Skeleton>
                )}
                {!loading && (
                        <ImageView
                            toolbar
                            viewRef={imageRef}
                            preview={false}
                            className="object-contain rounded-md h-full w-full"
                            imageClassName="object-contain rounded-md h-full w-full rounded-xl "
                            src={canvasImage || `data:image/webp;base64,${previewImage}`}
                            reset={imageReset}
                        />
                )}

                {/* Feedback buttons shown only if not all images are reviewed */}
                <div className="flex space-x-2 z-50 absolute bottom-5 left-1/2 transform -translate-x-1/2">
                    {/* Button for positive feedback (Thumbs Up) */}
                    <Button
                        variant="custom"
                        size="xs"
                        color={`${
                            isPositiveFeedback
                                ? 'trueBlue'
                                : 'chineseWhite'
                        }`}
                        icon={
                            <HiOutlineThumbUp className="text-xl" />
                        }
                        disabled={loading}
                        active={isPositiveFeedback}
                        textColor={`${
                            isPositiveFeedback
                                ? 'text-white'
                                : 'text-gray'
                        }`}
                        onClick={() => handleThumbAction(true)}
                    />
                    {/* Button for negative feedback (Thumbs Down) */}
                    <Button
                        variant="custom"
                        size="xs"
                        icon={
                            <HiOutlineThumbDown className="text-xl" />
                        }
                        disabled={loading}
                        active={isNegativeFeedback}
                        color={`${
                            isNegativeFeedback
                                ? 'trueBlue'
                                : 'chineseWhite'
                        }`}
                        textColor={`${
                            isNegativeFeedback
                                ? 'text-white'
                                : 'text-gray'
                        }`}
                        onClick={() => handleThumbAction(false)}
                    />
                    {showPopup && (
                        <div className="absolute -top-20 left-1/2 transform -translate-x-1/2 z-50">
                            <div className="relative -bottom-[25px] left-[15px] bg-white shadow-lg rounded-lg p-2 flex flex-col items-center">
                                <div className="absolute -bottom-[4px] left-1/5 transform -translate-x-1/2 w-0 h-0 border-x-4 border-x-transparent border-t-4 border-t-white"></div>
                                <div className="flex m-0 gap-1">
                                    {/* Buttons for selecting the reason for negative feedback */}
                                    {[
                                        'Missed Asset',
                                        'Asset wrongly identified',
                                    ].map((reason) => {
                                        const isActive =
                                            currentImageHasFeedback?.some(
                                                (feedback) =>
                                                    feedback.reason ===
                                                    reason,
                                            )
                                        return (
                                            <Button
                                                key={reason}
                                                size="xs"
                                                variant="twoTone"
                                                textColor="text-arsenic"
                                                active={isActive}
                                                className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded transition-colors duration-200"
                                                onClick={() =>
                                                    handleReasonSelect(
                                                        reason,
                                                    )
                                                }
                                            >
                                                {reason}
                                            </Button>
                                        )
                                    })}
                                    {/* Button to close the popup */}
                                    <Button
                                        size="xs"
                                        variant="custom"
                                        onClick={handlePopupClose}
                                    >
                                        <IoClose className="text-xl" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex-[1] flex items-start justify-center overflow-y-auto max-h-[80vh]">
                <div className="flex w-full flex-wrap justify-center gap-5 py-1 pt-2">
                    {loading && (
                        <div className="flex flex-row gap-5">
                            {Array.from(
                                {length: 3},
                                (_, index) => (
                                    <Skeleton
                                        key={`skeleton-${index}`}
                                        width="115px"
                                        height="115px"
                                        borderRadius="16px"
                                    />
                                ),
                            )}
                        </div>
                    )}
                    {!loading &&
                        images?.map((image, index) => {
                            const imageKey = `${image.blobName}-${index}`;
                            return (
                                    <Image
                                        key={imageKey}
                                        preview={false}
                                        className={`w-[115px] h-[115px] rounded-2xl`}
                                        imageClassName={`rounded-2xl object-cover w-[115px] h-[115px] ${
                                            selectedImage ===
                                            image.blobName
                                                ? 'border-2 border-trueBlue'
                                                : ''
                                        }`}
                                        src={`data:image/webp;base64,${image?.str}`}
                                        loading="lazy"
                                        onClick={() => {
                                            if (selectedImage === image.blobName) return;
                                            handlePreview(
                                                image?.blobName,
                                                index
                                            )
                                            setActiveImageIndex(index)
                                            setSelectedImage(
                                                image?.blobName,
                                            )
                                        }}
                                    />
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

export default Images
