import axios from 'axios'
// eslint-disable-next-line import/no-unresolved
import appConfig from './app.config'

export const TOKEN_TYPE = 'Bearer ';
export const REQUEST_HEADER_AUTH_KEY = 'Authorization';

const BaseService = axios.create({
    timeout: 600000,
    baseURL: appConfig.apiPrefix,
})

export function initServiceToken(accessToken: string) {
    BaseService.interceptors.request.use(
        (config) => {
            if (accessToken) {
                config.headers[
                    REQUEST_HEADER_AUTH_KEY
                ] = `${TOKEN_TYPE}${accessToken}`
            }
            return config;
        },
        (error) => {
            return Promise.reject(error)
        }
    );
}

export default BaseService
