export type AppConfig = {
    apiPrefix: string
    locale: string
}

console.log('NODE_ENV', process.env.NODE_ENV)
const apiPrefix = process.env.NODE_ENV === 'production' ? 'https://threev-nest-app.azurewebsites.net' : 'https://threev-nest-app.azurewebsites.net'

const appConfig: AppConfig = {
    apiPrefix,
    locale: 'en',
}

export default appConfig
