// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import Images from "./Images";
import BaseService from "../../global/BaseService";
import Loading from "../../components/custom/Loading";

export interface ModuleReviewProps {
    id: number
}

export type ReviewAsset = {
    id: number
    tenantId: number
    code: string
    type: ReviewAssetType
    geometry: string
    address: string
}
export enum ReviewAssetType {
    CIRCUIT = 'Circuit',
    POLE = 'Pole',
}

export type ReviewInspection = {
    id: number
    tenantId: number
    assetId: number
    inspectionTime: Date
    inspectionNumber: number
    createdAt: Date
    updatedAt: Date
    images: ReviewInspectionImage[]
}

export type ReviewInspectionImage = {
    id: number
    tenantId: number
    assetId: number
    inspectionId: number
    imageTime: Date
    blobName: string
    contentType: string
    createdAt: Date
    createdBy: number
    updatedAt: Date
    updatedBy: number
}

export default function ModuleReview({ id }: ModuleReviewProps) {
    const [loading, setLoading] = useState(false);
    const [asset, setAsset] = useState<ReviewAsset | null>(null);
    const [inspections, setInspections] = useState<ReviewInspection[]>([]);
    const [activeImageIndex, setActiveImageIndex] = useState<number>(0);

    useEffect(() => {
        getNotifications();
    // eslint-disable-next-line
    }, []);

    async function getNotifications() {
        setLoading(true);
        const param = {
            url: `/dashboard/asset/${id}`,
            method: 'get',
            params: {}
        };
        BaseService(param)
            .then((response: AxiosResponse<Response>) => {
                setLoading(false)
                setAsset(response.data.asset)
                setInspections(response.data.inspections)
            })
            .catch((e) => {
                console.log(e);
            });
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex w-full h-[50px] p-3 justify-center bg-gray-100 custom border-b border-customBorderColor">
                <div className="flex flex-row w-[85%] justify-between">
                    <div className="w-[150px]"><img src="/logo/skyscope.png" alt="logo" /> </div>
                    <p className="text-base font-bold">Asset Code: {asset?.code}</p>
                </div>
            </div>
            {loading && <Loading  loading={loading}/>}
            {asset && inspections.length &&
                <Images
                    asset={asset}
                    inspection={inspections[0]}
                    activeImageIndex={activeImageIndex}
                    setActiveImageIndex={setActiveImageIndex} />
            }
        </div>
    );
}
