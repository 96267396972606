// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export function addFileNameSuffix(fileName: string, suffix: string): string {
    const lasPointPos = fileName.lastIndexOf('.');
    const previous = fileName.substring(0, lasPointPos);
    // const next = fileName.substring(lasPointPos);
    return previous + suffix + '.webp';
}

export const getPreviewBlobName = (blobName: string) => addFileNameSuffix(blobName, '_pr')

export const getThumbBlobName = (blobName: string) => addFileNameSuffix(blobName, '_tb')


export const getPreviewBlobNameV2 = (blobName: string): string => {
    if (blobName.endsWith('_tb.webp')) {
        return blobName.replace('_tb.webp', '_pr.webp')
    }

    if (blobName.endsWith('.JPG')) {
        return blobName.replace('.JPG', '_pr.webp')
    }
    return blobName
}
