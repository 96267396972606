import React, { useEffect, useState } from 'react';
import './App.css';
import ModuleReview from './modules/review/ModuleReview';
import { MODULE_REVIEW, MODULES } from './global/consts';
import { initServiceToken } from './global/BaseService';

function App() {
  const [moduleName, setModuleName] = useState<string>('');
  const [id, setId] = useState<number>(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setModuleName(urlParams.get('moduleName') || '');
    setId(parseInt(urlParams.get('id') || '0'));
    initServiceToken(urlParams.get('token') || '');
    if (window.location.search) {
      // window.history.replaceState(null, '', '/')
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {moduleName === MODULE_REVIEW && <ModuleReview id={id} />}
        {!MODULES.includes(moduleName) && <p>No modules specified !</p>}
      </header>
    </div>
  );
}

export default App;
